import {Injectable} from '@angular/core';
import {RcApiService, RcEventBusService} from '@realcommerce/rc-packages';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {LanguageService} from './language.service';
import {CookieService} from 'ngx-cookie';
import {FunctionsService} from './functions.service';
import {CONSTANTS} from '../constants';
import {ILoginCredentials, IRedirectResponse} from '../models/auth.model';
import {AmdocsGatewayClientService} from '../amdocs-gateway-client/amdocs-gateway-client.service';

declare const apigClientFactory: any;

@Injectable({
  providedIn: 'root'
})
export class ApiService extends RcApiService {

  constructor(protected http: HttpClient, private languageService: LanguageService,
              private eventBus: RcEventBusService,
              private cookieService: CookieService, private functionsService: FunctionsService,
              private gatewayAPI: AmdocsGatewayClientService) {
    super(http);
  }

  // API CALLS
  public getTranslation() {
    const fileName = this.languageService.getLanguage();
    return this.http.get(`${environment.translationPath}${fileName}.json`);
  }


  public uploadReceipt(data) {
    console.log('data', data);
    const body = {
      IdToken: this.getIdToken(),
      Key: data.KEY,
      TransactionId: this.functionsService.createGuid(),
      Data: {
        TRAVEL: {
          TRAVEL_ID: data.TRAVEL_ID,
          START_DATE: data.START_DATE,
          END_DATE: data.END_DATE,
          COUNTRY_CODE: data.COUNTRY,
          EXPENSE_CODE: data.EXPENSE,
          CURRENCY_CODE: data.CURRENCY,
          FILE: data.UPLOAD_FILE.fileToUploadEncoded
        }

      }
    };

    const apiClient = this.getApiClient();
    if (data.FROM_EMAIL) {
      return from(apiClient.myreceiptsSaveAttachmentPut({'Content-Type': 'application/json'}, body)).pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          }
          return this.handleError(response);
        }),
        catchError((err) => {
          return this.handleError(err);
        }));
    } else {
      return from(apiClient.myreceiptsSaveFilePut({'Content-Type': 'application/json'}, body)).pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          }
          return this.handleError(response);
        }),
        catchError((err) => {
          return this.handleError(err);
        }));
    }


  }

  public getTravels() {
    const body = this.buildPayload();
    const apiClient = this.getApiClient();
    return from(apiClient.myreceiptsQueryRequestlistPost(null, body)).pipe(
      map((response: any) => {
        if (response.status === 200) {
          return response.data;
        }
        return this.handleError(response);
      }),
      catchError((err) => {
        return this.handleError(err);
      }));
  }

  public getAttachmentList() {
    const body = this.buildPayload();
    const apiClient = this.getApiClient();
    return from(apiClient.myreceiptsAttachmentObjectsPost(null, body)).pipe(
      map((response: any) => {
        if (response.status === 200) {
          return response.data;
        } else {
          return this.handleError(response);
        }
      }),
      catchError((err) => {
        return this.handleError(err);
      }));
  }

  public getAttachment(key) {
    const body = {
      IdToken: this.getIdToken(),
      Key: key
    };
    const apiClient = this.getApiClient();
    return from(apiClient.getAttachmentPost(null, body)).pipe(
      map((response: any) => {


        if (response.status === 200) {
          return response.data;
        }
        return this.handleError(response);
      }),
      catchError((err) => {
        return this.handleError(err);
      }));
  }

  public getGuideData(): Observable<any> {
    return this.apiGet('/assets/guide/guideData.json');
  }

  public getNotificationsList(handleError = true): Observable<any> {
    const body = this.buildPayload();
    const apiClient = this.getApiClient();
    return from(apiClient.myreceiptsGetEmpNotifPost(null, body)).pipe(
      map((response: any) => {
        if (response.status === 200) {
          if (response.data.Employee_Notification && !Array.isArray(response.data.Employee_Notification)) {
            response.data.Employee_Notification = [response.data.Employee_Notification];
          }
          return response.data;
        }
        return this.handleError(response, handleError);
      }),
      catchError((err) => {
        return this.handleError(err, handleError);
      }));
  }

  public getLoginUrl(): Observable<IRedirectResponse> {
    return this.gatewayAPI.getLoginApiGatewayClient().call('/oidc/login', 'get').pipe(
      mergeMap((response: IRedirectResponse) => {
        return of(response);
      })
    );
  }

  public login(code: string): Observable<ILoginCredentials> {
    const params = {
      code
    };
    return this.gatewayAPI.getLoginApiGatewayClient().call('/oidc/token', 'get', params).pipe(
      mergeMap((response) => {
        return of(response);
      })
    );
  }

  public getLogoutUrl(): Observable<IRedirectResponse> {
    return this.gatewayAPI.getLoginApiGatewayClient().call('/oidc/logout', 'post').pipe(
      mergeMap((response: IRedirectResponse) => {
        return of(response);
      })
    );
  }

  public refreshToken(): Observable<ILoginCredentials> {
    return this.gatewayAPI.getLoginApiGatewayClient().call('/oidc/refresh', 'get').pipe(
      mergeMap((response: ILoginCredentials) => {
        return of(response);
      })
    );
  }


  // END OF PUBLIC API CALLS

  /**
   * return api base url
   */
  protected getBaseUrl(): string {
    return environment.apiBaseUrl;
  }

  /**
   * Run a GET api call
   * @param path
   * @param requestParams
   * @param force
   * @param handleError
   * @return Observable<any>
   */
  private apiGet(path: string, requestParams: any = {}, force: boolean = true, handleError: boolean = true): Observable<any> {
    return this.get(path, requestParams, force).pipe(
      mergeMap((result: any) => {
        return of(result);
      }),
      catchError((error) => {
        return this.handleError(error, handleError);
      })
    );
  }

  /**
   * Run a POST api call
   * @param actionName
   * @param requestParams
   * @param handleError
   * @return Observable<any>
   */
  private apiPost(actionName: string, requestParams: any = {}, handleError: boolean = true): Observable<any> {
    return this.post(actionName, requestParams).pipe(
      switchMap((result: any) => {
        if (result.isSucceeded === true) {
          return of(result.content);
        } else {
          return throwError(result);
        }
      }),
      catchError((error) => {
        return this.handleError(error, handleError);
      })
    );
  }

  /**
   * Run a PUT api call
   * @param actionName
   * @param requestParams
   * @param handleError
   * @return Observable<any>
   */
  private apiPut(actionName: string, requestParams: any = {}, handleError: boolean = true): Observable<any> {
    return this.put(actionName, requestParams).pipe(
      mergeMap((result: any) => {
        if (result.isSucceeded === true) {
          return of(result.content);
        } else {
          return throwError(result);
        }
      }),
      catchError((error) => {
        return this.handleError(error, handleError);
      })
    );
  }

  /**
   * Run a DELETE api call
   * @param actionName
   * @param requestParams
   * @param handleError
   * @return Observable<any>
   */
  private apiDelete(actionName: string, requestParams: any = {}, handleError: boolean = true): Observable<any> {
    return this.delete(actionName, requestParams).pipe(
      mergeMap((result: any) => {
        if (result.isSucceeded === true) {
          return of(result.content);
        } else {
          return throwError(result);
        }
      }),
      catchError((error) => {
        return this.handleError(error, handleError);
      })
    );
  }

  protected setCustomHeaders(headers: HttpHeaders) {
    // todo
  }

  /**
   * Handle API errors
   * @param error
   * @param handleError
   */
  protected handleError(error, handleError = true): Observable<any> {
    if (handleError) {
      if (error && error.status) {
        if (error.status === 401) { // idToken expired
          this.eventBus.emit(CONSTANTS.EVENTS.SESSION_EXPIRED);
        } else if (error.status === 403) {
          // unhandled error
          this.eventBus.emit(CONSTANTS.EVENTS.GENERIC_POPUP);

        } else { // unhandled error
          this.eventBus.emit(CONSTANTS.EVENTS.GENERIC_POPUP);
        }
      } else {
        // unhandled error
        this.eventBus.emit(CONSTANTS.EVENTS.GENERIC_POPUP);
      }
    }
    return throwError(error);
  }

  protected returnErrorJson(error) {
    const result = {
      isSucceeded: false,
      error: null
    };
    let err: any = error;
    if (!error || !error.errors) {
      err = {
        code: 0,
        message: ''
      };
    } else {
      err = error.errors;
    }

    result.error = err;

    return result;
  }

  private buildPayload(obj: any = null) {

    let body = {
      TransactionId: this.functionsService.createGuid(),
      IdToken: this.getIdToken()
    };

    if (obj) {
      body = {...obj};
    }
    return body;
  }

  private getApiClient() {
    const region = 'eu-west-1';
    const credentials = {
      accessKey: this.cookieService.get('AccessKeyId'),
      secretKey: this.cookieService.get('SecretAccessKey'),
      sessionToken: this.cookieService.get('SessionToken'),
      region: region
    };
    return apigClientFactory.newClient(credentials);
  }

  private getAgentType() {
    return "Mobile";
  }

  private getIdToken() {
    return this.cookieService.get('open_id_token');
  }

  private getScreenId() {
    return 'D';
  }

  public getAllCurrencies() {
    return this.http.get(`/assets/currencies/currencies.json`);
  }


  public deleteAttachments(keys: string[]): Observable<any> {
    const body = {
      IdToken: this.getIdToken(),
      "Keys": keys
    };
    const apiClient = this.getApiClient();
    return from(apiClient.deleteAttachments(null, body)).pipe(
      map((response: any) => {
        if (response.status === 200) {
          return response.data;
        }
        return this.handleError(response);
      }),
      catchError((err) => {
        return this.handleError(err);
      })
    );
  }

}
